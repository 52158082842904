@use '@angular/material' as mat;
@use './colors';

$own-palette: (
  50: colors.$primary,
  100: colors.$primary,
  200: colors.$primary,
  300: colors.$primary,
  400: colors.$primary,
  500: colors.$primary,
  600: colors.$primary,
  700: colors.$primary,
  800: colors.$primary,
  900: colors.$primary,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
  ),
);

$primary: mat.m2-define-palette($own-palette, 600);
$accent: mat.m2-define-palette($own-palette, 500);
$warn: mat.m2-define-palette(mat.$m2-pink-palette, 500);
$font-family: 'Poppins', Sans-serif;

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: $font-family,
        $headline-5:
          mat.m2-define-typography-level(
            $font-weight: bold,
            $font-size: 30px,
          ),
        $headline-6:
          mat.m2-define-typography-level(
            $font-weight: bold,
            $font-size: 24px,
          ),
        $subtitle-1:
          mat.m2-define-typography-level(
            $font-weight: bold,
            $font-size: 18px,
          ),
        $button:
          mat.m2-define-typography-level(
            $font-weight: 500,
            $font-size: 14px,
            $letter-spacing: normal,
          ),
        $body-2:
          mat.m2-define-typography-level(
            $font-size: 16px,
          ),
      ),
    density: 0,
  )
);

@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($theme);
