// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'colors';
@use 'mixins';
@use 'theme';
@use 'global';
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Symbols+Outlined');

@import '../../node_modules/angular-notifier/styles';
@import './parts/angular-notifier-prompt-theme';
@import './parts/ai-assistant';
@import './parts/notify';
@import './datepicker';

@import 'material/mat-button';
@import 'material/mat-form-field';
@import 'material/mat-icon';
@import 'material/mat-dialog';
@import 'material/mat-card';
@import 'material/mat-checkbox';
@import 'material/mat-menu';
@import 'material/mat-stepper';
@import 'material/mat-select';
@import 'material/mat-tooltip';

@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

@include mat.core();

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: theme.$font-family;
  @include mixins.scrollbars(6px, #c2c2c2);
}

.mat-typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }
}

.tutorial-on.quick-actions-btn {
  right: 400px !important;
}

.label-tooltip {
  // to main table
  white-space: pre-line;
}

.pulse-tutorial {
  animation: pulse 1.3s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(colors.$primary, 0.8);
  }
  70% {
    -webkit-box-shadow: 0 0 0 12px rgba(colors.$primary, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(colors.$primary, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(colors.$primary, 0.8);
    box-shadow: 0 0 0 0 rgba(colors.$primary, 0.8);
  }
  70% {
    -moz-box-shadow: 0 0 0 12px rgba(colors.$primary, 0);
    box-shadow: 0 0 0 12px rgba(colors.$primary, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(colors.$primary, 0);
    box-shadow: 0 0 0 0 rgba(colors.$primary, 0);
  }
}
