@use 'colors';

$topbarHeight: 64px;

// New "YELLOW" theme
$newVeryDarkColor: #1a1a1a;
$newGrayColor: #5a5a58;
$newOrangeColor: #f5e313;
$primaryBgGradient: linear-gradient(26.7deg, #f5c313 23.45%, #ffee98 166.9%);

// CSS variables to be set for whitelabels
:root {
  --brandPrimaryBg: #f5c313;
  --brandSecondaryBg: #1e88e5;
  --brandPrimaryTxt: #202223;
  --brandSecondaryTxt: #1a1a1a;
  --brandHighlight: #26c6da;
  --brandDataTableHeader: #26c6da;
  --brandDataTableRowEven: #f2f4f8;
  --brandDataTableRowOdd: #fff;
}

// New "YELLOW" theme
$brandPrimaryBgColor: colors.$primary;
$brandPrimaryTxtColor: var(--brandPrimaryTxt);
$brandSecondaryTxtColor: var(--brandSecondaryTxt);
$brandHighlightColor: var(--brandHighlight);
/* Data table */
$brandDataTableHeaderColor: colors.$primary;
$brandDataTableRowEvenColor: var(--brandDataTableRowEven);
$brandDataTableRowOddColor: var(--brandDataTableRowOdd);

// summary
$cardsCommentColor: #dbeeff;
$widgetCommentColor: #c1f6d7;

// Refactor
$primary: colors.$primary;
$primaryV2: #f5c313;
$secondary: var(--brandSecondaryBg);
$secondaryV2: #1e88e5;
// default text
$defaultTextColor: #202223;
$inactiveTextColor: #8c8c8c;

$poppins-font-family: Poppins;

// colors
$gold: #ffd700;
$inactiveColor: #8c8c8c;
$gray: #5c5f62;
$greenLight: #c1f6d7;
$greenTextColor: #0c5132;
$lightGray: #e1e3e5;
$light-blue: #26c6da;
$mint: #aee9d1;
$registerGray: #51575b;
$white: #ffffff;
$lightGrayLang: #959595;
$lightGrayV2: #6d7175;
$paginateGrayText: #8c9196;
$placeholderColor: #8c9196;
$darkColorV3: #8c9196;
$paginateGrayBackground: #d2d5d866;
$paginateGrayBorderDisabled: #e9e9e9;
$paginateGrayBorderActive: #babfc3;
$linkTextColor: #2c6ecb;
$mobile-breakpoint: 768px;
$tablet-breakpoint: 992px;
$white: #fff;
$darkBackgroundColor: #202223;
$badgeDark: #4f4700;
$badgeYellow: #ffee98;
$selectGrey: #f1f1f1;
$calendarCell: #f2f7fe;
$blueLight: #dbeeff;
$blueText: #00527c;
$blueV2: #4a90e2;

$borderColor: #d7d7d7;
$border: rgba(120, 130, 140, 0.13);

$dark-text: #848a96;
$darkColorV2: #5c5f62;

$success: #07d400;
$error: #d60027;
$error2: #f6d7d6;
$error3: #e11e03;
$danger: #fc4b6c;
$info: #1e88e5;
$blue: #02bec9;

$greyColorV1: #ebeaea;
$greyColorV2: #babfc3;
$greyBackgroundColor: #f6f6f7;

$inactive: #efefef;

$inactiveColor: #8c8c8c;
$inactiveBorderColor: #a9a9a9;
$boxShadowStyleV1: 6px 6px 16px 0 rgba(0, 0, 0, 0.05);

$yellowToRightGradient: linear-gradient(to right, #f5c3134d 50%, #ffffff);

$aiAssistantBorderColor: #ebeaea;

$highlightedGreen: #aee9d1;
$highlightedRed: #fed3d1;
$highlightedNeutral: #f0f0f0;

// @TODO: Jak podepniemy bootstrapa/tailwinda to podmienić:
$color-blue: #007bff;
$color-indigo: #6610f2;
$color-purple: #6f42c1;
$color-pink: #e83e8c;
$color-red: #dc3545;
$color-orange: #fd7e14;
$color-yellow: #ffc107;
$color-green: #28a745;
$color-teal: #20c997;
$color-cyan: #17a2b8;
$color-white: #fff;
$color-gray: #6c757d;
$color-gray-dark: #343a40;
$color-primary: #007bff;
$color-secondary: #6c757d;
$color-success: #28a745;
$color-info: #17a2b8;
$color-warning: #ffc107;
$color-danger: #dc3545;
$color-light: #f8f9fa;
$color-dark: #343a40;
