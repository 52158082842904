/* Pamiętaj że globalne style to OSTATECZNOŚĆ - unikaj jak ognia i dodawaj tylko, gdy jesteś pewny, że to najlepszy sposób! :) */
@import 'variables';
@import 'mixins';

// MODALS
.mat-mdc-dialog-container {
  .mat-mdc-dialog-title {
    @include flex-center-vertical;
    @include font-params(16px !important, 500 !important, null, 0);
    border-bottom: 1px solid $greyColorV1;
    color: $defaultTextColor !important;
    justify-content: start;
    padding: 16px 24px;
  }

  mat-dialog-content {
    margin: 16px;
  }
}

sem-chart text,
sem-chart .apexcharts-legend-text {
  font-family: $poppins-font-family, Helvetica, Arial, sans-serif !important;
}

// HIDE RADIO FOR TASKS SLIDER
.slider-radio-hidden {
  .mdc-radio {
    display: none !important;
  }

  .mdc-form-field {
    width: 100%;

    .mdc-label {
      cursor: pointer;
      width: 100%;
    }
  }
}

// FORMAT MAT-MENU FIELD IN TASKS SLIDER
.slider-menu-field {
  .mat-mdc-menu-item-text {
    display: flex;
    gap: 5px;
    width: 100%;
  }

  .mat-mdc-select-arrow-wrapper {
    display: none !important;
  }
}

// MENU PANEL
.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      @include flex-center-vertical;
      color: $defaultTextColor;

      mat-icon {
        font-size: 18px;
        height: 18px;
        margin-right: 4px;
      }

      .mat-mdc-menu-item-text {
        @include font-params(15px, 400, null, 0);
      }
    }
  }
}

// TASK DESCRIPTION/COMMENT LINKS IN EDITABLE MODE
.ngx-editor > div > div > p > a {
  cursor: pointer;
}
