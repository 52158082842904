/* stylelint-disable */
// NOTIFIER: ERROR TYPE STYLES

$notifier-prompt-background-color: #fff !default;
$notifier-prompt-font-color: #000 !default;
$notifier-prompt-icon-color: #000 !default;

.notifier__notification--prompt {
  background-color: $notifier-prompt-background-color;
  color: $notifier-prompt-font-color;
  border: 1px solid #1e88e5;
  padding: 20px;

  .notifier__notification-button-icon {
    // 16x16 fixed size
    fill: $notifier-prompt-icon-color;
  }
}
