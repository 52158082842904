.mat-mdc-dialog-actions {
  justify-content: end !important;
  padding: 24px !important;
}

.confirm-dialog {
  width: 50vw !important;
  min-width: auto !important;

  .mat-mdc-dialog-actions {
    justify-content: center !important;
  }
}
