@include mat.form-field-density(-5);

mat-form-field:has(button[mat-icon-button]) {
  @include mat.icon-button-density(-5);
}

// https://github.com/angular/components/issues/26352
mat-dialog-content,
*[mat-dialog-content] {
  > mat-form-field:first-child:first-of-type {
    margin-top: 5px !important;
  }
}
