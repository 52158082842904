/* media queries */
@use 'sass:color';
@use 'sass:math';
@use 'sass:meta';

@mixin tablet-down {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile-down {
  @media (max-width: 667px) {
    @content;
  }
}

@mixin arrowAnimation {
  transition: transform 0.3s;
  user-select: none;

  &.up {
    transform: rotateZ(-90deg);
  }
  &.down {
    transform: rotateZ(90deg);
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: color.mix($foreground-color, white, 50%)) {
  &::-webkit-scrollbar {
    transition: all 0.3s ease-in;
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    transition: all 0.3s ease-in;
    background: color.mix($foreground-color, white, 50%);
  }

  &::-webkit-scrollbar-track {
    transition: all 0.3s ease-in;
    background: color.mix($foreground-color, white, 30%);
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: color.mix($foreground-color, white, 30%);
    scrollbar-track-color: color.mix($foreground-color, white, 50%);
  }

  &:hover {
    &::-webkit-scrollbar {
      width: $size;
      height: $size;
    }

    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
      background: $background-color;
    }

    // For Internet Explorer
    body {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
  }
}

@mixin no-select {
  outline: 0;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin dragAndDrop {
  .cdk-drag-preview {
    background-color: white;
    padding: 20px 10px;
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .cdk-drag-placeholder {
    opacity: 0;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.cdk-drop-list-dragging *:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

@mixin chipDisplay {
  display: flex;
  align-items: center;
  &:last-of-type {
    margin-right: 20px;
  }

  .from-item-description {
    font-size: 9px;
    color: $dark-text;
    margin-top: 2px;
    margin-left: 3px;
  }
}

@mixin popup {
  ::ng-deep mat-dialog-container {
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 20px !important;
    right: 24px !important;
  }
}

@mixin inputFont {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

// Creates a box with optional width and height.
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

// Limits text to a specified number of lines and adds ellipsis.
// Use @include clamp(3) to limit text to 3 lines.

@mixin clamp($number) {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

// Ensures background covers the element and is centered.
@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// Flex column
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

// Center items flex
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Center items in column
@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

// Vertical centering in flex
@mixin flex-center-vertical {
  display: flex;
  align-items: center;
}

// Horizontal centering in flex
@mixin flex-center-horizontal {
  display: flex;
  justify-content: center;
}

// Sets font properties with optional weight, line height, and letter spacing.
// Use @include font-params(16px, 700) for 16px text size and 700 weight.
@mixin font-params($font-size, $font-weight: null, $line-height: null, $letter-spacing: null) {
  @if meta.type-of($font-size) == number and math.unit($font-size) == '' {
    font-size: $font-size * 1px;
  } @else {
    font-size: $font-size;
  }

  @if $font-weight {
    font-weight: $font-weight;
  }

  @if $line-height {
    line-height: $line-height;
  }

  @if $letter-spacing {
    letter-spacing: $letter-spacing;
  }
}

// Media query mixins for specific device or viewport sizes.

// Desktop only styles
@mixin desktop-only {
  @media only screen and (min-width: $tablet-breakpoint + 1px) {
    @content;
  }
}

// Tablet only styles
@mixin tablet-only {
  @media only screen and (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint) {
    @content;
  }
}

// Mobile only styles
@mixin mobile-only {
  @media only screen and (max-width: $mobile-breakpoint - 1px) {
    @content;
  }
}

// Not mobile styles > 768px
@mixin not-mobile {
  @media only screen and (min-width: $mobile-breakpoint) {
    @content;
  }
}

// Not desktop styles < 992px
@mixin not-desktop {
  @media only screen and (max-width: $tablet-breakpoint) {
    @content;
  }
}

// Custom max width
@mixin media-custom-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// Custom min width
@mixin media-custom-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

// Custom width range
// Using @include media-custom-range(1200px, 1920px)

@mixin media-custom-range($min-width, $max-width) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

// Position mixin for setting position values relative | absolute | fixed | sticky, top, right, bottom, and left values.
@mixin position($position: false, $top: false, $right: false, $bottom: false, $left: false) {
  @if $position {
    @if ($position == 'sticky') {
      position: -webkit-sticky; /* Safari */
    }

    position: $position;
  }
  @if $top {
    top: $top;
  }
  @if $right {
    right: $right;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $left {
    left: $left;
  }
}
