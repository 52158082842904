@import 'variables';

@mixin mainText {
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: 400 !important;
  color: $defaultTextColor !important;
}

@mixin defaultText {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: $defaultTextColor;
}

@mixin bold {
  font-weight: 600;
}

@mixin defaultTextWithHover {
  @include defaultText;
  -webkit-transition: color 0.3s;
  -ms-transition: color 0.3s;
  transition: color 0.3s;
  &:hover {
    color: #44474a;
  }
}

@mixin defaultTextGray {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: $inactiveTextColor;
}

@mixin disableTextSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
